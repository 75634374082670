import React from "react"

export interface SplitViewImageProps {
  src: string
  alt: string
  className?: string
}

const SplitViewImage: React.SFC<SplitViewImageProps> = ({
  src,
  alt,
  className,
}) => {
  return (
    <div className="justify-center flex">
      <div>
        <img
          src={src}
          alt={alt}
          title={alt}
          className={`lg:w-full sm:w-5/12 w-10/12 max-h-full max-w-full mx-auto ${className}`}
        />
      </div>
    </div>
  )
}

export default SplitViewImage
