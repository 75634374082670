import React from "react"
import Section from "./Section"
import { Button } from "./text/Button"
import { H2 } from "./text/H2"
import { Paragraph } from "./text/Paragraph"

export interface CTAElementProps {
  background?: boolean
  patient?: boolean
}

const CTAElement: React.SFC<CTAElementProps> = ({ background, patient }) => {
  return (
    <Section background={background}>
      <div className="sm:w-1/2 mx-auto">
        <H2
          text={
            patient === true
              ? "Ihre Psychotherapeut*in nutzt die Psychotherapie App noch nicht?"
              : "Sie möchten unsere App ausprobieren?"
          }
        />
        {patient === true ? (
          <>
            <Paragraph>
              Dann teilen Sie gerne den Link zu unserer Website mit Ihre*m
              Psychotherapeut*in, damit auch Sie bald von der Psychotherapie App
              profitieren können.
            </Paragraph>
            <Paragraph>
              <Button
                text="Mit Psychotherapeut*in teilen"
                newTab
                to="mailto:?body=Hier ist der Link zur Website: diepsychotherapieapp.de&subject=Psychotherapie App ausprobieren"
              />
            </Paragraph>
          </>
        ) : (
          <>
            <Paragraph>
              Dann vereinbaren Sie ein 30 minütiges Beratungsgespräch für Sie
              oder Ihr komplettes Team mit uns, indem Sie auf den Button
              "Beratungsgespräch vereinbaren" klicken. Oder testen Sie die App
              direkt durch einen Klick auf den Link "Direkt ausprobieren (für
              immer kostenlos)".
            </Paragraph>
            <Paragraph>
              Übrigens wird die App <strong>für immer kostenlos</strong> sein.
              Wir streben eine Anerkennung bei den gesetzlichen Krankenkassen
              an, die für die Entwicklungskosten aufkommen sollen. So bleibt die
              App für jeden zugänglich. Unabhängig des Einkommens.
            </Paragraph>
            <Button
              text="Beratungsgespräch vereinbaren"
              to="https://calendly.com/psychotherapie-jonas/einfuhrungsgespraech"
              className="text-center"
              newTab
            />
            <div className="w-full mt-2">
              <a
                className="text-primary-600 hover:text-primary-800 border-b-2 border-primary-600 hover:border-primary-900 w-full"
                href="https://app.diepsychotherapieapp.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Direkt ausprobieren (für immer kostenlos)
              </a>
            </div>
          </>
        )}
      </div>
    </Section>
  )
}

export default CTAElement
