import React from "react"
import { H2 } from "../text/H2"
import ThreeColumns from "../ThreeColumns"
import DevicesIllustration from "../../images/devices_illustration.svg"
import QualityCertificationIllustration from "../../images/quality_certification_illustration.svg"
import SecurityIllustration from "../../images/security_illustration.svg"
import CostsIllustration from "../../images/kosten_illustration.svg"

const inputAllgemein = [
  {
    illustration: {
      src: DevicesIllustration,
      alt: "nutzbar auf allen Endgeräten Illustration",
    },
    heading: "nutzbar auf allen Endgeräten",
    text: `Übung daheim am Laptop beginnen und in der Bahn am Smartphone weitermachen`,
  },
  {
    illustration: {
      src: SecurityIllustration,
      alt: "Datensicherheit und Datenschutz Illustration",
    },
    heading: "maximale Datensicherheit und Datenschutz",
    text: `durch vollständigen Verzicht auf Speicherung von personenbezogenen Daten und Einhaltung von höchsten Sicherheitsstandards`,
  },
  {
    illustration: {
      src: QualityCertificationIllustration,
      alt: "gesicherter Qualitätsstandard Illustration",
    },
    heading: "gesicherter Qualitätsstandard",
    text: `angestrebte CE Kennzeichnung und Listung als digitale Gesundheitsanwendung durch das Bundesinstitut für Arzneimittel und Medizinprodukte`,
  },
  {
    illustration: {
      src: CostsIllustration,
      alt: "Kostenlose App Illustration",
    },
    heading: "für immer kostenlos",
    text: `Bezahlung erfolgt in Zukunft durch gesetzliche Krankenkassen; davor auch völlig kostenlos`,
  },
]

export interface TrustFunctionsProps {}

const TrustFunctions: React.SFC<TrustFunctionsProps> = () => {
  return (
    <>
      <div className="w-full">
        <H2
          text="Unser Versprechen"
          className="text-center text-3xl md:text-4xl xl:text-5xl"
        />
      </div>
      <ThreeColumns
        input={inputAllgemein}
        button={{ to: "/faq", text: "Mehr erfahren" }}
        background
      />
    </>
  )
}

export default TrustFunctions
