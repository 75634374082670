import React from "react"
import ThreeColumns from "../ThreeColumns"
import CreateExerciseIllustration from "../../images/create_exercise_illustration.svg"
import AudioMaterialIllustration from "../../images/audio_material_illustration.svg"
import PlanTasksIllustration from "../../images/plan_tasks_illustration.svg"
import VideoIllustration from "../../images/video_streaming_illustration.svg"
import ReminderIllustration from "../../images/reminder_illustration.svg"
import { H2 } from "../text/H2"

export interface FunktionenProps {}

const inputTherapeut = [
  {
    illustration: {
      src: CreateExerciseIllustration,
      alt: "Übungen erstellen Illustration",
    },
    heading: "Übungen erstellen",
    text: (
      <ul>
        <li>Übungen aus Drag und Drop Elementen zusammenstellen</li>
        <li>Textbausteine, Eingabefelder, Checkboxen, Skalen, etc. einfügen</li>
      </ul>
    ),
  },
  {
    illustration: {
      src: AudioMaterialIllustration,
      alt: "Text, Audio-/ Videomaterial einbetten Illustration",
    },
    heading: "Text, Audio-/ Videomaterial einbetten",
    text: (
      <ul>
        <li>einfaches nutzerfreundliches Hochladen von eigenem Material</li>
      </ul>
    ),
  },
  {
    illustration: {
      src: PlanTasksIllustration,
      alt: "Übungen Patient*innen zuweisen Illustration",
    },
    heading: "Übungen Patient*innen zuweisen",
    text: (
      <ul>
        <li>
          Start-/ Enddatum und Empfehlung zur Häufigkeit der Ausführung
          hinzufügen
        </li>
      </ul>
    ),
  },
]

const inputPatient = [
  {
    illustration: {
      src: AudioMaterialIllustration,
      alt: "Übungen anwenden Illustration",
    },
    heading: "Übungen anwenden",
    text: (
      <ul>
        <li>Instruktionen über die App befolgen und Zusatzmaterial nutzen</li>
      </ul>
    ),
  },
  {
    illustration: {
      src: VideoIllustration,
      alt: "Zusatzinhalte abrufen Illustration",
    },
    heading: "Zusatzinhalte abrufen",
    text: (
      <ul>
        <li>
          beispielsweise aufgenommene Achtsamkeitsübungen oder psychoedukative
          Videos
        </li>
      </ul>
    ),
  },
  {
    illustration: {
      src: ReminderIllustration,
      alt: "Erinnerungen stellen Illustration",
    },
    heading: "Erinnerungen stellen",
    text: (
      <ul>
        <li>Wecker und Nachrichten zur Erinnerung stellen</li>
      </ul>
    ),
  },
]

const Funktionen: React.SFC<FunktionenProps> = () => {
  return (
    <>
      <H2
        text="Wie verwendet man die App?"
        className="text-center text-3xl md:text-4xl xl:text-5xl"
      />
      <div className="my-12">
        <H2 text="Psychotherapeut*innen" className="text-center" />
        <ThreeColumns
          input={inputTherapeut}
          button={{
            to: "/funktionen/psychotherapeut_innen",
            text: "Mehr erfahren",
          }}
        />
      </div>
      <div className="my-12">
        <H2 text="Patient*innen" className="text-center" />
        <ThreeColumns
          input={inputPatient}
          button={{ to: "/funktionen/patient_innen", text: "Mehr erfahren" }}
        />
      </div>
    </>
  )
}

export default Funktionen
