import React from "react"

export interface SubheadlineProps {
  text: string
  className?: string
  mb?: number
}

export const Subheadline: React.SFC<SubheadlineProps> = ({
  text,
  className,
  mb,
}) => {
  return (
    <p
      className={`text-gray-600 leading-relaxed md:text-2xl text-xl mb-${
        mb ? mb : 8
      } ${className}`}
    >
      {text}
    </p>
  )
}
