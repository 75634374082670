import React from "react"

export interface MobilePresentationImageProps {
  src: string
  alt: string
}

const MobilePresentationImage: React.SFC<MobilePresentationImageProps> = ({
  src,
  alt,
}) => {
  return (
    <div className="justify-center flex">
      <div>
        <img src={src} alt={alt} className="h-500 xl:h-700" />
      </div>
    </div>
  )
}

export default MobilePresentationImage
