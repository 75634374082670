import React, { ReactElement } from "react"
import Layout from "../components/Layout"
import AppScreenshot from "../images/screenshots/Therapeut_innen_Aufgaben.png"
import TasksIllustation from "../images/tasks_illustration.svg"
import HangoutIllustration from "../images/hang_out_illustration.svg"
import VideoIllustation from "../images/video_illustration.svg"
import ErfolgIllustation from "../images/erfolg_illustration.svg"
import Section from "../components/Section"
import SplitView from "../components/SplitView"
import ThreeColumns from "../components/ThreeColumns"
import Funktionen from "../components/index/Funktionen"
import { H1 } from "../components/text/H1"
import { H2 } from "../components/text/H2"
import { Button } from "../components/text/Button"
import SplitViewImage from "../components/SplitViewImage"
import { Subheadline } from "../components/text/Subheadline"
import { Paragraph } from "../components/text/Paragraph"
import TrustFunctions from "../components/index/TrustFunctions"
import MobilePresentationImage from "../components/MobilePresentationImage"
import CTAElement from "../components/CTAElement"

const therapieerfolgInput = [
  {
    illustration: {
      src: TasksIllustation,
      alt: "Effektive Übungen Psychotherapie",
    },
    heading: "Sie als Psychotherapeut*in implementieren effektive Übungen,",
    text:
      "Erfolg bleibt jedoch aus, da diese von Patient*innen nicht aktiv in den Alltag integriert werden",
  },
  {
    illustration: {
      src: HangoutIllustration,
      alt: "Psychotherapie Übungen im Alltag anwenden",
    },
    heading: "Patient*innen möchten im Alltag Übungen anwenden,",
    text:
      "benötigen dafür allerdings Unterlagen/Instruktionen, die sie nicht mit sich führen",
  },
  {
    illustration: {
      src: VideoIllustation,
      alt: "Digitales Übungsmaterial Psychotherapeut*in",
    },
    heading:
      "Sie als Psychotherapeut*in verfügen über digitales Übungsmaterial",
    text:
      "(Audio-/ Videoaufnahmen), haben aber keine geeignete Plattform, über die Sie diese mit Ihren Patient*innen teilen können",
  },
]

interface Props {}

function Index(_props: Props): ReactElement {
  return (
    <Layout>
      <SplitView
        elementLeft={
          <>
            <H1 text="Digitale Unterstützung für Psychotherapeut*innen" />
            <Subheadline
              text="begleitendes Tool zur Erstellung und Erledigung von Hausaufgaben
              während der Psychotherapie"
            />
            <div>
              <Button
                text="Beratungsgespräch vereinbaren"
                to="https://calendly.com/psychotherapie-jonas/einfuhrungsgespraech?month=2021-03&date=2021-03-11"
                className="text-center"
                newTab
              />
              <div className="w-full mt-2">
                <a
                  className="text-primary-600 hover:text-primary-800 border-b-2 border-primary-600 hover:border-primary-900 w-full"
                  href="https://app.diepsychotherapieapp.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Direkt ausprobieren (für immer kostenlos)
                </a>
              </div>
            </div>
          </>
        }
        elementRight={
          <MobilePresentationImage src={AppScreenshot} alt="Screenshot App" />
        }
        scrollId="erfolg"
      />

      <Section className="text-center" background id="erfolg">
        <H2 text="Therapieerfolg wäre wahrscheinlicher," mb={2} />
        <Subheadline
          text="wenn Patient*innen erarbeitete Übungen konsequenter anwenden"
          mb={0}
        />
        <ThreeColumns input={therapieerfolgInput} />
      </Section>

      <SplitView
        elementLeft={
          <SplitViewImage
            src={ErfolgIllustation}
            alt="Erfolg mit der Psychotherapie App Illustration"
          />
        }
        elementRight={
          <>
            <H2
              text="Die Psychotherapie App könnte Therapieerfolg wahrscheinlicher machen..."
              className="px-4"
            />
            <div className="p-4 mb-4 rounded shadow-md">
              <Paragraph mb={0}>
                ...da Sie als Therapeut*in Übungen individualisiert und in
                anschaulicherem Format zu Verfügung stellen können
              </Paragraph>
            </div>
            <div className="p-4 mb-4 rounded  shadow-md">
              <Paragraph mb={0}>
                ...da Sie als Therapeut*in die Möglichkeit haben zusätzliche
                Materialien (Bilder, Audio-/Videoaufnahmen) einzubetten
              </Paragraph>
            </div>
            <div className="p-4 mb-4 rounded  shadow-md">
              <Paragraph mb={0}>
                ...da Patient*innen über die App von überall Zugriff auf die
                Übungen haben und sie regelmäßiger durchführen können
              </Paragraph>
            </div>
          </>
        }
      />

      <Section background>
        <Funktionen />
      </Section>

      <Section>
        <TrustFunctions />
      </Section>

      <CTAElement background />
    </Layout>
  )
}

export default Index
