import React from "react"
import { Button } from "./text/Button"
import { H3 } from "./text/H3"
import { Paragraph } from "./text/Paragraph"

export type ThreeColumnInput = {
  illustration: { src: string; alt: string; noSVG?: boolean }
  heading: string
  text: string | JSX.Element | Element
}

export interface ThreeColumnsProps {
  input: ThreeColumnInput[]
  background?: boolean
  button?: { to: string; text: string; newTab?: boolean }
}

const ThreeColumns: React.SFC<ThreeColumnsProps> = ({
  input,
  background,
  button,
}) => {
  return (
    <div
      className={`flex flex-wrap justify-center items-center -mx-8 mt-12 mb-2 text-center ${
        background !== true ? "bg-white" : "bg-gray-100"
      } p-8 shadow-md`}
    >
      {input.map((item, index) => (
        <div
          className={
            input.length > 3
              ? `w-full sm:w-4/8 lg:w-1/4 px-8`
              : `w-full sm:w-4/6 lg:w-1/3 px-8`
          }
          key={item.heading}
        >
          <img
            className={`${
              item.illustration.noSVG !== true ? "w-1/2" : "w-auto"
            } mx-auto mb-8 h-32`}
            src={item.illustration.src}
            alt={item.illustration.alt}
            title={item.illustration.alt}
          />
          <H3 text={item.heading} />
          <Paragraph>{item.text}</Paragraph>
        </div>
      ))}
      {button !== undefined && (
        <div className="w-full mt-5">
          <Button text={button.text} to={button.to} newTab={button.newTab} />
        </div>
      )}
    </div>
  )
}

export default ThreeColumns
