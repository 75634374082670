import React, { ReactElement } from "react"
import Section from "./Section"
export interface SplitViewProps {
  elementLeft: ReactElement
  elementRight: ReactElement
  heading?: ReactElement
  background?: Boolean
  scrollId?: string
}

const SplitView: React.SFC<SplitViewProps> = ({
  elementLeft,
  elementRight,
  heading,
  background,
  scrollId,
}) => {
  return (
    <Section background={!!background}>
      {heading}
      <div className="flex flex-wrap items-center text-center lg:text-left -mx-2">
        <div className="w-full lg:w-1/2 px-2 lg:pr-10 mt-10 lg:mt-0 order-1 lg:order-none">
          {elementLeft}
        </div>
        <div className="w-full lg:w-1/2 px-2">{elementRight}</div>
      </div>
      {scrollId !== undefined && (
        <a href={`#${scrollId}`}>
          <div className="text-center xl:mt-36 mt-24 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 inline-block animate-bounce"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 14l-7 7m0 0l-7-7m7 7V3"
              />
            </svg>
          </div>
        </a>
      )}
    </Section>
  )
}

export default SplitView
